import { template as template_5f8ec15b6bc347a79a70c52ff86144a7 } from "@ember/template-compiler";
const WelcomeHeader = template_5f8ec15b6bc347a79a70c52ff86144a7(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
