import { template as template_9e97bfcddc1e4172ba144e7d509d1048 } from "@ember/template-compiler";
const FKText = template_9e97bfcddc1e4172ba144e7d509d1048(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
