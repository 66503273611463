import { template as template_6a8188697d6f4dce88e50124678600ac } from "@ember/template-compiler";
const FKLabel = template_6a8188697d6f4dce88e50124678600ac(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
