import { template as template_04441ad205284c50b9c3f1e13cacfe3d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_04441ad205284c50b9c3f1e13cacfe3d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
